@import "variables";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

ul {
    margin-bottom: 0;
    padding-left: 0;
}

li {
    list-style-type: none;
}

a {
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0;
}

p {
    margin-bottom: 0;
}

.flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.agreement {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;

    img {
        height: auto;
        width: 50%;
        border: 1px solid rgb(238, 238, 238);
    }
}

.changeColor {
    color: #000 !important;
}

.navigation_alignment {
    margin-bottom: 1rem;
    position: relative !important;
}

.submit-btn {
    outline: none;
    border: none;
    display: block;
    width: 100%;
    background: $primary-color;
    color: $white-color;
    font-weight: 600;
    padding: 0.6rem 0;
    border-radius: 0.4rem;

    i {
        margin-left: 0.3rem;
    }
}

.zaf-title {
    border-left: 0.4rem solid $primary-color;
    border-radius: 0.6rem;
    padding-left: 0.8rem;
    margin-bottom: 1.6rem;

    h3 {
        font-size: 1.8rem;
        font-weight: 600;
    }

    p {
        font-size: 1.2rem;
        font-weight: 700;
        color: $primary-color;
    }
}

.zaweyat-alnada-facility-breadcrumb {
    height: 20rem;
    overflow: hidden;
    position: relative;

    .breadcrumb-info {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        h2 {
            text-align: center;
            font-size: 2rem;
            font-weight: 600;
        }

        p {
            text-align: center;
            font-size: 1.4rem;
            font-weight: 600;
        }
    }

    .video-container {
        margin-top: -2rem;

        video {
            height: 100%;
            width: 100%;
        }
    }
}

.zaweyat_alnada_facility_managemenet {

    .zaweyat-alnada-navigation-section {
        position: absolute;
        top: 0;
        left: 0;
        background-color: transparent;
        width: 100%;
        z-index: 100;

        .navigation-parent {
            padding: 0.8rem 0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .navigation-childs {
                &:first-child {
                    img {
                        height: auto;
                        width: 7rem;
                    }
                }

                &:last-child {
                    .menu {
                        ul {
                            display: flex;

                            li {
                                margin-right: 2rem;

                                a {
                                    color: $white-color;
                                    font-weight: 600;
                                    font-size: 1.2rem;

                                    &:hover {
                                        color: #dfdfdf;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    // banner-section::begin3
    .zaweyat-alnada-banner-section {
        height: 42rem;
        width: 100%;
        background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../images/services/party_helper.jpg");
        background-size: cover;
        background-position: center;
        position: relative;
        // effect
        $s1: "";
        $s2: "";
        $s3: "";

        @for $i from 1 through 400 {
            $s1: $s1 + random(1000)*0.1vw + " "+ random(1000)*0.1vh + " "+ 0 + " "+ random(50)*-0.01rem + #fff;
            $s2: $s2 + random(1000)*0.1vw + " "+ random(1000)*0.1vh + " "+ 0 + " "+ random(50)*-0.01rem + #fff;
            $s3: $s3 + random(1000)*0.1vw + " "+ random(1000)*0.1vh + " "+ 0 + " "+ random(50)*-0.01rem + #fff;

            @if $i < 400 {
                $s1: $s1 + ",";
                $s2: $s2 + ",";
                $s3: $s3 + ",";
            }
        }

        // effect
        .snowy-effect {
            opacity: 0.5;
        }

        .snow {
            border-radius: 50%;
            opacity: 0.1;
            position: absolute;
            top: -100vh;
            animation-name: fall;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
        }

        @keyframes fall {
            100% {
                transform: translateY(200vh);
            }
        }

        .layer1 {
            width: 1.5rem;
            height: 1.5rem;
            filter: blur(1.5px);
            box-shadow: #{$s1};
            animation-duration: 20s;
        }

        .layer1.first-snow {
            animation-delay: -3s;
        }

        .layer2 {
            width: 1.2rem;
            height: 1.2rem;
            filter: blur(3px);
            box-shadow: #{$s2};
            animation-duration: 16s;
        }

        .layer2.first-snow {
            animation-delay: -4s;
        }

        .layer3 {
            width: 0.8rem;
            height: 0.8rem;
            filter: blur(6px);
            box-shadow: #{$s3};
            animation-duration: 24s;
        }

        .layer3.first-snow {
            animation-delay: -5s;
        }

        // effect

        .zaweyat-alnada-banner-info {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 80%;

            h3 {
                color: $white-color;
                text-shadow: 0 0 3px #000000, 0 0 5px #000000;
                font-weight: 500;
                font-size: 3rem;
                text-align: center;
            }

            p {
                color: $white-color;
                text-align: center;
                font-size: 1.6rem;
                text-shadow: 0 0 3px #000000, 0 0 5px #000000;
            }

            .zaweyat-alnada-facility-social-media {
                margin-top: 2rem;

                .zaweyat-alnada-facility-social-media-wrapper {
                    display: flex;
                    justify-content: center;

                    .zaweyat-alnada-facility-social-media-wrapper-inner {
                        width: 2.2rem;
                        height: 2.2rem;
                        margin-right: 1rem;
                        border: 2px solid $white-color;
                        padding: 0.5rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 0.4rem;

                        &:hover {
                            background: $primary-color;
                            color: $white-color;
                        }

                        a {
                            i {
                                color: $white-color;
                            }
                        }
                    }

                }
            }

            .zaweyat-alnada-banner-action-btns {
                display: flex;
                justify-content: center;
                margin-top: 2rem;

                button {
                    background: $primary-color;
                    border: 2px solid $white-color;
                    border-radius: 2rem;
                    outline: none;
                    padding: 0.5rem 1.2rem;
                    margin-right: 1rem;
                    color: $white-color;
                    font-size: 1rem;

                    &:hover {
                        background: $white-color;
                        color: #000;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    // banner-section::end

    // services::begin
    .services-section {
        padding: 3rem 0;
        background: #f8f8f8;

        .services-wraper {
            .services-container {
                background: $white-color;
                border-radius: 0.2rem;
                overflow: hidden;
                border: 1px solid #d8d8d8;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
                margin-bottom: 2.4rem;

                &:hover {
                    img {
                        transform: scale(1.1);
                    }
                }

                .services-inner-wrapper {
                    overflow: hidden;

                    img {
                        height: 240px;
                        width: 100%;
                        object-fit: cover;
                        object-position: center;
                        transition: 0.4s;
                    }
                }

                .services-info {
                    padding: 0.5rem 1rem;

                    h1 {
                        font-size: 1.1rem;
                        text-transform: uppercase;
                        font-weight: 600;
                        margin: 0.6rem 0;
                    }

                    p {
                        font-size: 1rem;
                        font-weight: 600;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                    }

                    .services-know-more {
                        margin-top: 0.6rem;

                        a {
                            background: $primary-color;
                            padding: 0.4rem 1rem;
                            display: block;
                            color: $white-color;
                            border-radius: 0.4rem;
                            transition: 0.4s;

                            &:hover {
                                background: $bright-color;
                            }
                        }
                    }
                }
            }
        }
    }

    // services::end

    // counter::begin
    .zaweyat-alnada-counter-section {
        background: linear-gradient(to right, transparent 50%, rgba(0, 86, 112, 0.8) 50%), url("../../assets/images/services/party_helper.jpg");
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-position: top;

        .zaweyat-alnada-video-wrapper {
            background: $white-color;
            box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
            padding: 0rem 0.4rem;

            h3 {
                text-align: center;
                justify-content: center;
                font-size: 1.2rem;
                font-weight: 600;
                padding: 0.4rem 0;
            }

            video {
                height: auto;
                width: 100%;
            }
        }

        .counter-parent {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 2rem 0;
            margin-top: 1.2rem;

            .counter-childs {
                flex: 1;
                width: 100%;

                h1 {
                    color: #fff;
                    font-weight: 600;
                    text-align: center;

                    i {
                        margin-right: 1rem;
                        width: 2rem;
                    }
                }

                p {
                    color: $white-color;
                    text-align: center;
                    font-size: 1.2rem;
                    font-weight: 600;
                    margin-top: 0.5rem;
                }
            }
        }
    }

    // counter::end

    // about::begin
    .zaweyat-alnada-about-us-section {
        padding: 2rem 0;
        position: relative;

        .about-us-deco-images {
            img {
                height: auto;
            }

            .img_deo_1 {
                position: absolute;
                top: 1rem;
                right: 1rem;
                width: 16rem;
            }

            .img_deo_2 {
                position: absolute;
                top: 2rem;
                left: 1rem;
                width: 10rem;
                transform: rotate(45deg);
            }
        }

        .about-us-parent {
            display: flex;

            .about-us-child {
                flex: 1;

                &:first-child {
                    .about-feature-image {
                        img {
                            height: 100%;
                            width: 100%;
                        }
                    }
                }

                &:last-child {
                    padding-left: 2rem;

                    .about-description {
                        h3 {
                            font-size: 1.6rem;
                            font-weight: 600;
                        }

                        p {
                            font-size: 1.1rem;
                            font-weight: 600;
                            margin-top: 1.4rem;
                        }
                    }
                }
            }
        }

        .zaweyat-alnada-facility-why-choose-parent {
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin-top: 1rem;

            .zaweyat-alnada-facility-why-choose-childs {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                h3 {
                    font-size: 1rem;
                    font-weight: 600;
                    margin-top: 1rem;
                }

                .why-choose-us-icon {
                    height: 6rem;
                    width: 6rem;
                    background: $primary-color;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 0.6rem;

                    i {
                        font-size: 1.8rem;
                        color: $white-color;
                    }
                }
            }
        }
    }

    // about::end

    // contact::begin
    .contact-section {
        background: linear-gradient(rgba(255, 255, 255, 0.94), rgba(255, 255, 255, 0.94)), url("../images/services/unnamed.jpg");
        background-size: cover;
        padding: 3rem;

        .contact-page-wrapper {
            .contact-information {
                text-align: center;
                border: 2px solid $primary-color;
                border-radius: 0.5rem;
                padding: 2rem;
                margin-bottom: 2rem;
                transition: 0.2s;

                &:hover {
                    background: $primary-color;

                    i,
                    h3 {
                        color: $white-color;
                    }
                }

                i {
                    font-size: 2rem;
                    color: $primary-color;
                    margin-bottom: 0.5rem;
                }

                h3 {
                    font-size: 1.2rem;
                    font-weight: 600;
                }
            }
        }

        .contact-form {
            form {
                h3 {
                    font-size: 1.5rem;
                    font-weight: 600;
                }

                p {
                    font-size: 1rem;
                    font-weight: 600;
                    color: red;
                    margin-bottom: 1rem;
                }

                label {
                    font-size: 1rem;
                    font-weight: 600;
                    margin-bottom: 0.4rem;
                }

                input,
                textarea {
                    width: 100%;
                    padding: 0.4rem 0.8rem;
                    border-radius: 0.3rem;
                    outline: none;
                    border: 1px solid #c9c9c9;

                    &:focus {
                        border: 1px solid $primary-color;
                    }
                }
            }
        }
    }

    // contact::end

    // footer::begin
    .footer-section {
        background-color: $primary-color;
        padding: 2rem 0;

        .connect-with-us-facebook {
            iframe {
                height: 24rem;
            }
        }

        .footer-logo {
            .footer-logo-img {
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    height: auto;
                    width: 6rem;
                }
            }
        }

        .footer-contact-info {
            margin-top: 1.2rem;

            ul {
                li {
                    font-weight: 500;
                    color: $white-color;
                    margin: 0.8rem 0;
                    font-weight: 600;

                    a {
                        color: $white-color;
                    }

                    i {
                        margin-right: 0.6rem;
                        width: 1rem;
                    }
                }
            }
        }

        .footer-social-icons {
            margin-top: 1.6rem;

            ul {
                display: flex;
                justify-content: flex-start;

                a {
                    li {
                        height: 2.5rem;
                        width: 2.5rem;
                        border-radius: 0.2rem;
                        background: $white-color;
                        margin-right: 1rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &:hover {
                            background-color: $secondary-color;

                            i {
                                color: $white-color;
                            }
                        }

                        i {
                            font-size: 1rem;
                            color: $primary-color;
                        }
                    }
                }
            }
        }

        .footer-links {
            margin-top: 2rem;

            h3 {
                color: $white-color;
                font-size: 1.2rem;
                font-weight: 600;
            }

            ul {
                padding-left: 0;

                li {
                    margin: 0.8rem 0;
                    font-weight: 600;

                    a {
                        color: $white-color;
                        white-space: nowrap;

                        &:hover {
                            color: $secondary-color;
                        }

                        i {
                            margin-right: 0.6rem;
                        }
                    }
                }
            }
        }
    }

    .copy-right {
        background: #003161;
        padding: 0.4rem 0;

        .copy-right-parent {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .copy-right-childs {
                p {
                    color: $white-color;
                    font-weight: 400;
                    font-size: 0.9rem;
                    margin-bottom: 0;

                    a {
                        color: $white-color;
                    }
                }
            }
        }
    }


    // footer::end
}

.comming-soon-page {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        height: auto;
        width: 50%;
    }
}

// animation::begin
@keyframes fadeUpAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

// animation::end


// responsiveness::begin
@media only screen and (max-width: 3000px) {

    .container {
        max-width: 1260px !important;
    }

    .zaweyat_alnada_facility_managemenet .zaweyat-alnada-banner-section {
        background-position: top;
    }

}

@media only screen and (max-width: 1920px) {
    .zaweyat_alnada_facility_managemenet .zaweyat-alnada-banner-section {
        background-position: center;
    }
}

@media only screen and (max-width: 768px) {
    html {
        font-size: 90%;
    }

    .zaweyat_alnada_facility_managemenet .zaweyat-alnada-banner-section .zaweyat-alnada-banner-info h3 {
        font-size: 1.6rem;
    }

    .navigation-parent {
        .navigation-childs {
            &:last-child {
                display: none;
            }
        }
    }

    .zaweyat-alnada-banner-action-btns {
        flex-direction: column;

        button {
            margin: 0.5rem 0;
        }
    }

    .zaweyat_alnada_facility_managemenet .zaweyat-alnada-about-us-section .about-us-parent .about-us-child:last-child {
        padding-left: 0;
    }
    .zaweyat_alnada_facility_managemenet .zaweyat-alnada-about-us-section .about-us-parent {
        flex-direction: column;
    }

    .zaweyat_alnada_facility_managemenet .zaweyat-alnada-about-us-section .zaweyat-alnada-facility-why-choose-parent {
        flex-direction: column;
    }
    .zaweyat_alnada_facility_managemenet .zaweyat-alnada-about-us-section .zaweyat-alnada-facility-why-choose-parent .zaweyat-alnada-facility-why-choose-childs {
        margin-bottom: 1rem
    }

    .breadcrumb-info {
        display: none;
    }

    .agreement img {
        width: 90%;
    }
}

// responsiveness::begin